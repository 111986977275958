module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"default":"/opt/build/repo/src/components/layout.tsx","extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-smartypants","gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"linkImagesToOriginal":false,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStype":"margin-bottom: 1.0725rem"}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"charlesbadger.dev","description":"A web development blog where I talk about stuff I'm learning","lang":"en","short_name":"starter","start_url":"/","background_color":"#000","theme_color":"#FFF","display":"minimal-ui","icon":"images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"60ac9339f9741a6f5e919d54772466cc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172669991-1","head":false,"respectDNT":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
